import React from 'react';
import PropTypes from 'prop-types';
import { SearchPage } from '../components/Views/SearchPage';
import {
  getDataForTransformationsQuery,
  searchTotalCountQuery
} from '../queries';
import {
  SearchItemsTitleService,
  fromUrlToObj,
  getSeoData,
  getValueFromObject,
  adaptFiltersForState,
  SearchItemsH1Service
} from '../utils/helpers';
import i18n from '../utils/i18n';
import Layout from '../components/Layout/Layout';
import {
  setCategoryIds,
  setChosenLocationNames,
  setCurrentState,
  setSearchSort,
  setCurrentCity
} from '../actions';
import { Cities, BUILDING_TYPE_ID } from '../utils/helpers/constants';
import { getStaticData } from '../utils/helpers/getStaticData';

const Search = ({ transformedObj, totalAdsCount, h1, ...rest }) => {
  return (
    <SearchPage
      transformedObj={transformedObj}
      totalAdsCount={totalAdsCount}
      h1={h1}
      {...rest}
    />
  );
};

Search.getInitialProps = async ({
  apolloClient,
  asPath,
  query,
  res,
  req,
  store
}) => {
  const [dataForTransform, staticData, seoData] = await Promise.all([
    apolloClient.query({ query: getDataForTransformationsQuery }),
    getStaticData(apolloClient, true),
    getSeoData(apolloClient, asPath, req)
  ]);

  const transformedObj = fromUrlToObj(dataForTransform, query);

  if (res && transformedObj.noMatch) {
    res.statusCode = 404;

    return {
      statusCode: 404
    };
  }
  const locationsIds = transformedObj?.filter?.locationIds || [];
  const storedFormState = store.getState().searchForm.currentState;
  const objForStore = adaptFiltersForState(transformedObj?.filter);
  const cityId =
    objForStore?.city_id || storedFormState?.city_id || Cities.Baku.id;
  store.dispatch(
    setCurrentState({
      ...storedFormState,
      ...objForStore,
      city_id: cityId,
      category_id:
        objForStore?.category_id ||
        storedFormState?.category_id ||
        BUILDING_TYPE_ID.FLAT_NEW
    })
  );

  store.dispatch(setCategoryIds([objForStore.category_id]));
  store.dispatch(setCurrentCity(cityId));
  // eslint-disable-next-line
  const { category_touched, ...filtersWithoutCategoryTouched } =
    transformedObj.filter;
  transformedObj.filter = filtersWithoutCategoryTouched;
  const totalAdsCountData = await apolloClient.query({
    query: searchTotalCountQuery(filtersWithoutCategoryTouched),
    variables: {
      filter: filtersWithoutCategoryTouched
    }
  });

  store.dispatch(
    setSearchSort(
      getValueFromObject(
        dataForTransform.data.sort,
        transformedObj.sorting,
        'id'
      ) || '1'
    )
  );
  store.dispatch(
    setChosenLocationNames(
      dataForTransform.data.locations
        .filter(location => locationsIds.includes(location.id))
        .map(location => location.name.trim())
    )
  );

  if (!seoData.title || !seoData.description) {
    seoData.title = new SearchItemsTitleService(
      dataForTransform.data,
      transformedObj.filter,
      i18n
    ).getTitle();

    if (!seoData.h1) {
      seoData.h1 = new SearchItemsH1Service(
        dataForTransform.data,
        transformedObj.filter,
        i18n
      )
        .getH1()
        .trim();
    }

    seoData.description = i18n.t('default_search_description');
  }

  return {
    transformedObj,
    seoData,
    totalAdsCount: totalAdsCountData?.data?.itemsConnection?.totalCount,
    h1: seoData.h1,
    isSearchPage: true,
    ...staticData
  };
};

Search.propTypes = {
  transformedObj: PropTypes.object,
  totalAdsCount: PropTypes.number,
  h1: PropTypes.string,
  citiesUnPopularList: PropTypes.array,
  regions: PropTypes.object,
  categories: PropTypes.array
};

Search.getLayout = (page, pageProps) => (
  <Layout isBackgroundBanner pageProps={pageProps}>
    {page}
  </Layout>
);

export default Search;
