import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import {
  setAdriverSelectedCity,
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import { GeneralAdsPage } from '../../GeneralAdsPage';
import { BUILDING_TYPE_ID, Cities } from '../../../utils/helpers/constants';
import { CategoriesQuery } from '../../../graphql/graphql';

interface SearchPageProps {
  transformedObj: {
    filter: { [key: string]: string | boolean };
    sorting?: string;
  };
  totalAdsCount?: number;
  h1?: string;
  categories: CategoriesQuery['categories'];
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setErrorUI: (errorState: boolean) => dispatch(setError(errorState))
  };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CombinedProps = SearchPageProps & PropsFromRedux;

const SearchPageComponent: React.FC<CombinedProps> = ({
  transformedObj,
  setErrorUI,
  totalAdsCount,
  h1,
  categories
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const resetForm = () => {
    const resetData = {
      city_id: Cities.Baku.id,
      leased: 'false',
      category_id: BUILDING_TYPE_ID.FLAT_NEW
    };
    formEl?.current?.reset(resetData);
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentCity(Cities.Baku.id));
    dispatch(setCurrentState({ ...resetData, category_touched: false }));
    dispatch(setAdriverSelectedCity(Cities.Baku.az));

    router.push('/');
  };

  return (
    <GeneralAdsPage
      {...{
        transformedObj,
        setErrorUI,
        h1,
        resetForm,
        totalAdsCount,
        categories
      }}
      ref={formEl}
    />
  );
};

export const SearchPage = connector(SearchPageComponent);
